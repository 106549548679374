$primary: #FD7E14;
$secondary: #33302E;

$text-color: #000000;

$white: #ffffff;
$black: #000000;

$border-color: #DCDCDC;

$background-primary: $white;
$background-secondary: #F2F2F2;

$background-disabled: #d8d8d8;
$text-disabled-color: $black;

$success-color-border: #37D002;
$info-color-border: #2845E8;
$warning-color-border: #AB8A13;
$danger-color-border: #E82837;

$success-color-background: #F4F9EB;
$info-color-background: #E6EEFF;
$warning-color-background: #FEF9E8;
$danger-color-background: #FFF2F0;